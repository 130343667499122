import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import './styles.scss';

import SuccessImg from '../../../utilities/images/requestSuccess.svg';
import ErrorImg from '../../../utilities/images/requestError.svg';

const dictionary = {
        success: {
            img: SuccessImg,
            heading: {ru: 'Заявка принята',kz: 'Өтініш қабылданды',en: 'Application accepted'},
            message: {ru: 'Спасибо! Ваша заявка была успешно создана.\nВ ближайшее время с Вами свяжется наш менеджер.',kz: 'Рақмет сізге! Өтінішіңіз сәтті жасалды.\nМенеджеріміз жақын арада сізбен байланысады.',en: 'Thank you! Your application has been successfully created.\nOur manager will contact you shortly.'},
        },
        error: {
            img: ErrorImg,
            heading: {ru: 'Что-то пошло не так',kz: 'Бірдеңе дұрыс болмады',en: 'Something went wrong'},
            message: {ru: 'К сожалению, сервер временно недоступен.\nПопробуйте еще раз позже',kz: 'Кешіріңіз, сервер уақытша қолжетімсіз.\nКейінірек қайталап көріңіз',en: 'Sorry, the server is temporarily unavailable.\nPlease try again later'},
        }
    };

const Modal = ({show=false, setShow, type=null}) => {
    const {lang} = useSelector((state)=>state.global);

    const close = () => {
        setShow(false);
    }

    return (
        <div className={`modal-overlay ${show ? '' : 'hide'}`} onClick={close}>
            {type ? (
                <div className='modal'>
                    <div className='modal-body'>
                        <span className='modal-close' onClick={close}>
                            <i></i>
                        </span>
                        <div className='modal-img'>
                            <img src={dictionary[type].img} />
                        </div>
                        <div className='modal-header'>
                            {dictionary[type].heading[lang]}
                        </div>
                        <div className='modal-content'>
                            {dictionary[type].message[lang]}
                        </div>
                    </div>
                </div>
            ) : (
                <div className='modal-loading'>loading</div>
            )}
        </div>
    );
};

export default Modal;