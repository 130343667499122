import React from "react";

import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import Home from "./components/Home/Home";
import Header from "./components/subcomponents/Header/Header";
import About from "./components/About/About";
import Footer from "./components/subcomponents/Footer/Footer";
import Subpage from "./components/Subpage/Subpage";
import Page404 from "./components/Page404/Page404";

const App = () => {
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<>
                    <Header />
                    <Outlet/> 
                    <Footer />
                </>}>
                    <Route path="" exact element={<Home />}/>
                    <Route path="/home" exact element={<Navigate to="/" replace />} />
                    <Route path="about" exact element={<About />}/>
                    {["directions", "services"].map((path, index) => (
                        <Route path={path} key={index}>
                            <Route path=":pagename" element={<Subpage parent={path} /> } />
                        </Route>
                    ))}
                    <Route path="*" element={<Page404 />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
