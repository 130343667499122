import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux'
import axios from 'axios';
import {useLocation} from 'react-router-dom';

import './styles.scss';
import { LANG } from "../../../constants/actionTypes";
import { addVisitor } from "../../../actions/visitor";
import { getPages } from "../../../actions/page";
import moment from 'moment';
import { getLangs } from "../../../actions/lang";

const pages = {
    directions: {ru: 'Направления', en: 'Directions', kz: 'Бағыттар'},
    services: {ru: 'Услуги', en: 'Services', kz: 'Қызметтер'},
    about: {ru: 'О нас', en: 'About us', kz: 'Біз туралы'},
    contacts: {ru: 'Контакты', en: 'Contacts', kz: 'Байланыстар'},
};

const Header = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    
    const {pages:pgs, page} = useSelector((state) => state.pages);
    const {lang, langs: newLangs} = useSelector((state) => state.global);
    
    const [blocks, setBlocks] = useState([]);
    const [currentBlock, setCurrentBlock] = useState({i: NaN, y: NaN, h: NaN, theme: 'dark'});
    const [theme, setTheme] = useState('light');
    
    const visitor = JSON.parse(localStorage.getItem('visitor'));
    // const [langs, setLangs] = useState(JSON.parse(localStorage.getItem('langs')));
    const [langs, setLangs] = useState(null);

    // useEffect(()=>{
    //     if(!lang)
    //         dispatch({type: LANG, lang: 'ru'});
    // }, [lang]);
    useEffect(()=>{
        if(newLangs){
            setLangs(newLangs);
            let currLangInArr = false;
            if(lang){
                newLangs.forEach(newLang => {
                    if(newLang.name === lang)
                        currLangInArr = true;
                });
            }
            if(!lang || !currLangInArr)
                dispatch({type: LANG, lang: newLangs[0].name});
        }
    },[newLangs])
    useEffect(()=>{dispatch(getPages())}, [dispatch]);

    useEffect(()=>{
        if(!langs){
            dispatch(getLangs());
        }
    }, [langs]);

    // CHANGE HEADER STYLING
    const handleScroll = () => {
        const y = window.scrollY + 60;
        if(blocks.length>0){
            if(y < currentBlock.y && currentBlock.i > 0){
                const newBlock = blocks[currentBlock.i-1];
                setCurrentBlock(newBlock);
                setTheme(newBlock.theme);
            }else if(y > currentBlock.h+currentBlock.y && currentBlock.i < blocks.length-1){
                const newBlock = blocks[currentBlock.i+1];
                setCurrentBlock(newBlock);
                setTheme(newBlock.theme);
            }
        }
    }
    window.addEventListener('scroll', handleScroll)
    useEffect(()=>{
            const elBlocks = document.getElementsByClassName('block');
            const newBlocks = [];

            for (let index = 0; index < elBlocks.length; index++) {
                const block = elBlocks[index];
                var h = Math.round(block.getBoundingClientRect().height);
                var y = block.offsetTop; 
                const rgb = (window.getComputedStyle(block).backgroundColor).split('(')[1].split(')')[0].replace(/ /g, '').split(',');   
                const grey = Math.round((Number(rgb[0]) + Number(rgb[1]) + Number(rgb[2]))/3); 
                const theme = (grey >= 128) ? 'dark' : 'light';
                newBlocks.push({y, h, theme, i: index});
            }
            setBlocks(newBlocks);
            y = window.scrollY + 60;

            if(newBlocks.length>0){
                for (let index = 0; index < newBlocks.length; index++) {
                    const block = newBlocks[index];
                    if(block.y <= y && (block.h+block.y) >= y){
                        setCurrentBlock(block);
                        if(theme !== block.theme){
                            setTheme(block.theme);
                        }
                        break;
                    }
                }
            }
    }, [page])
    //CHANGE LANGUAGE 
    const changeLanguage = (e) => {
        e.preventDefault();
        dispatch({type: LANG, lang: e.currentTarget.dataset.lang});
    };

    // VISITOR

    
    const saveVisitor = async () => {
        const res = await axios.get('https://geolocation-db.com/json/');
        const ip = res.data.IPv4;
        const date = new Date(new Date().setUTCHours(0,0,0,0));

        const oneDay = (1000 * 60 * 60 * 24);
        const dayOfWeek = date.getDay() === 0 ? 7 : date.getDay();
        const month = moment(date).format('MMMM');

        const weekStart = new Date(date.valueOf() + oneDay * (1-dayOfWeek));
        const weekEnd = new Date(date.valueOf() + oneDay * (7-dayOfWeek));

        const week = moment(weekStart).format('DD/MM/YYYY') + " - " + moment(weekEnd).format('DD/MM/YYYY');



        dispatch(addVisitor({ip, date: moment(date).format('DD/MM/YYYY'), week, month}));
    }
    const compareVisitors = async (visitor) => {
        const res = await axios.get('https://geolocation-db.com/json/');
        const ip = res.data.IPv4;
        const date = new Date(new Date().setUTCHours(0,0,0,0));
    

        if(visitor.ip !== ip || new Date(visitor.data).setUTCHours(0,0,0,0) !== date){
            saveVisitor();
        }
    }
    useEffect(()=>{
        if(visitor && visitor.ip && visitor.date){
            compareVisitors(visitor);
        }else{
            saveVisitor();
        }
    }, [location])

    const [showMenu, setShowMenu] = useState(false);

    const openMenu = (e) => {
        e.preventDefault();
        setShowMenu(!showMenu);
    }

    const toggleActive = (e) => {
        // e.preventDefault();
        e.currentTarget.classList.toggle('active');
    }



    return (
        <div id="header" className={`theme-${theme}`}>
            <div className="container">
                <div>
                    <a className="brand" href="/"><i className="logo"></i></a>
                    <div className={`collapse ${showMenu ? 'active' : ''}`}>
                        <ul id="page_titles">
                            <li className="dropdown" onClick={toggleActive}>
                                <a className="dropbtn">{pages.directions[lang]}</a>
                                    {pgs && pgs.map((pg, i)=>{
                                        if(pg.subpages.length > 0 && pg.name === 'directions')
                                            return ( 
                                                <ul className="dropcontent" key={i}>
                                                    {pg.subpages.map((subpage, j) => (
                                                        <li key={`${j}`}>
                                                            <a href={`/${pg.name}/${subpage.name}`}>{subpage.title[lang]}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )
                                        
                                    })}
                            </li>
                            <li className="dropdown" onClick={toggleActive}>
                                <a className="dropbtn">{pages.services[lang]}</a>
                                    {pgs && pgs.map((pg, i)=>{
                                        if(pg.subpages.length > 0 && pg.name === 'services')
                                            return ( 
                                                <ul className="dropcontent" key={i}>
                                                    {pg.subpages.map((subpage, j) => (
                                                        <li key={`${j}`}>
                                                            <a href={`/${pg.name}/${subpage.name}`}>{subpage.title[lang]}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )
                                        
                                    })}
                            </li>
                            <li>
                                <a href='/about'>{pages.about[lang]}</a>
                            </li>
                            <li>
                                <a href='#footer'>{pages.contacts[lang]}</a>
                            </li>
                        </ul>
                        {langs && langs.length>1 && (
                            <div id="language" className="dropdown">
                                <a className="dropbtn">
                                    {((!lang || lang==='ru') && (<>
                                        RU
                                    </>)) || (lang==='en' && (<>
                                        EN
                                    </>)) || (lang==='kz' && (<>
                                        ҚАЗ
                                    </>))}
                                </a>
                                <ul className="dropcontent">
                                    {langs.map((lg, key)=>(
                                        <li key={key} data-lang={lg.name} className={lang === lg.name ? 'hidden' : ''} onClick={changeLanguage}>
                                            <a>
                                                {/* <i data-lang={lg.name} className={`lang-${lg.name}`}></i> */}
                                                {lg.name==='en'?'EN': (lg.name==='ru'?'RU':'ҚАЗ')}
                                            </a>
                                        </li>    
                                    ))}
                                    {/* <li data-lang="en" className={lang === 'en' ? 'hidden' : ''} onClick={changeLanguage}>
                                        <a>
                                            <i data-lang="en" className="lang-en"></i>EN
                                        </a>
                                    </li>
                                    <li data-lang="ru" className={(!lang || lang === 'ru') ? 'hidden' : ''} onClick={changeLanguage}>
                                        <a>
                                            <i data-lang="ru" className="lang-ru"></i>RU
                                        </a>
                                    </li>
                                    <li data-lang="kz" className={lang === 'kz' ? 'hidden' : ''} onClick={changeLanguage}>
                                        <a>
                                            <i data-lang="kz" className="lang-kz"></i>ҚАЗ
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div id="menu-hamburger"  className={showMenu ? 'active' : ''} onClick={openMenu}><i></i></div>
                </div>
            </div>
        </div>
    );
}

export default Header;