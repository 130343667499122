import * as api from '../api';
import { REQUEST_CONSULTATION } from '../constants/actionTypes';

export const requestConsultation = (formData) => async (dispatch) => {
    try {
        const data = await api.requestConsultation(formData);
        dispatch({type: REQUEST_CONSULTATION, payload: data});
    } catch (error) {
        console.log(error);
    }
}