import { ADD_VISITOR, CLEARE_CONSULTATION, FETCH_LANGS, LANG, REQUEST_CONSULTATION } from "../constants/actionTypes";

// http://89.219.32.45:5000/
const globalReducers = (state = {link: process.env.REACT_APP_SERVER_DEV,lang: localStorage.getItem('lang'), consultation_status: null}, action) => {
    switch (action.type) {
        case LANG:
            localStorage.setItem('lang', action.lang);
            return {...state, lang: action.lang};
        case FETCH_LANGS:
            const langs = action.payload.data;
            // if(langs)
            //     localStorage.setItem('langs', JSON.stringify(langs));
            return {...state, langs};
        case ADD_VISITOR:
            if(!!action.payload && !!action.payload.data && action.payload.status === 200){
                localStorage.setItem('visitor', JSON.stringify({...action.payload.data}));        
            }
            return state;
        case REQUEST_CONSULTATION: 
            return {...state, consultation_status: action.payload.status};
        case CLEARE_CONSULTATION:
            return {...state, consultation_status: null};
        default:
            return state;
    }
};
export default globalReducers;