import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTexts } from "../../../actions/page";

import './styles.scss';

const pages = {
    home: {ru: 'Главная страница', en: 'Main page', kz: 'Басты бет'},
    directions: {ru: 'Направления', en: 'Directions', kz: 'Бағыттар'},
    services: {ru: 'Услуги', en: 'Services', kz: 'Қызметтер'},
    about: {ru: 'О нас', en: 'About us', kz: 'Біз туралы'},
    dev: {ru: 'Разработка сайта - Wave', en: 'Website development - Wave', kz: 'Веб-сайт әзірлеуші - Wave'},
    terms: {ru: 'Пользовательское соглашение', en: 'Terms of use', kz: 'Пайдаланушы келісімшарты'},
    policy: {ru: 'Политика конфиденциальности', en: 'Privacy Policy', kz: 'Құпиялық саясаты'},
    questions: {ru: 'По всем вопросам:', en: 'For all questions:', kz: 'Барлық сұрақтар бойынша:'}, 
};

const Footer = () => {
    const {lang} = useSelector((state) => state.global);
    const {texts} = useSelector((state)=>state.pages);
    const dispatch = useDispatch();
    useEffect(()=>{
        if(!texts){
            dispatch(getTexts(['phone_number', 'email', 'whatsapp', 'instagram', 'facebook', 'linkedin','home_directions_heading', 'home_directions_subheading', 'home_services_heading', 'home_services_subheading']));
        }
    },[dispatch])
    return (
        <div id="footer">
            <div className="container">
                <div className="content">
                    <div className="row-1 row-lg-3">
                        <div className="col">
                            <a className="brand" href="/">
                                <i className="logo"></i>
                            </a>
                            <ul className="additional-links">
                                {/* <li>
                                    <a>FAQ</a>
                                </li> */}
                                <li>
                                    <a>{pages.terms[lang]}</a>
                                </li>
                                <li>
                                    <a>{pages.policy[lang]}</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                            <ul className="sitemap">
                                <li> 
                                    <a href='/'>
                                        {pages.home[lang]}
                                    </a>
                                </li>
                                <li> 
                                    <a href='/#directions'>
                                        {pages.directions[lang]}
                                    </a>
                                </li>
                                <li> 
                                    <a href='/#services'>
                                        {pages.services[lang]}
                                    </a>
                                </li>
                                <li> 
                                    <a href='/about'>
                                        {pages.about[lang]}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                            <div className="d-flex flex-column flex-lg-column-reverse">
                                <div className="social_media">
                                    {texts && texts.whatsapp && texts.whatsapp.all && (
                                        <a className="social" target="_blank" href={`https://api.whatsapp.com/send?phone=${texts.whatsapp.all}`}>
                                            <i className="whatsapp-icon"></i>
                                        </a>
                                    )}
                                    {texts && texts.instagram && texts.instagram.all && (
                                        <a className="social" target="_blank" href={`https://www.instagram.com/${texts.instagram.all}`}>
                                            <i className="instagram-icon"></i>
                                        </a>
                                    )}
                                    {texts && texts.facebook && texts.facebook.all && (
                                        <a className="social" target="_blank" href={`https://www.facebook.com/${texts.facebook.all}`}>
                                            <i className="facebook-icon"></i>
                                        </a>
                                    )}
                                    {texts && texts.linkedin && texts.linkedin.all && (
                                        <a className="social" target="_blank" href={`https://www.linkedin.com/in/${texts.linkedin.all}`}>
                                            <i className="linkedin-icon"></i>
                                        </a>
                                    )}
                                </div>
                                <div className="contacts">
                                    <p className="regular-16-28">{pages.questions[lang]}</p>
                                    {texts && texts.phone_number && texts.phone_number.all && (
                                        <a className="regular-22-28" href={`tel:${texts.phone_number.all}`}>{texts.phone_number.all}</a>
                                    )}
                                    {texts && texts.email && texts.email.all && (
                                        <a className="regular-18-28" href={`mailto:${texts.email.all}`}>{texts.email.all}</a>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <a className="dev" href="https://waveteam.dev" target="_blank">{pages.dev[lang]}</a>
                </div>
            </div>
        </div>
    )
};

export default Footer;