// GLOBAL
export const LANG = "LANG";
export const FETCH_LANGS = "FETCH_LANGS";
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';

export const ADD_VISITOR = 'ADD_VISITOR';
export const REQUEST_CONSULTATION = 'REQUEST_CONSULTATION';
export const CLEARE_CONSULTATION = 'CLEARE_CONSULTATION';
// PAGES
export const FETCH_PAGE = 'FETCH_PAGE';
export const FETCH_PAGES = 'FETCH_PAGES';
export const CLEAR_PAGE = 'CLEAR_PAGE';
export const FETCH_TEXTS = 'FETCH_TEXTS';

export const FETCH_PARTNERS = 'FETCH_PARTNERS';

