import axios from 'axios';


const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_DEV, validateStatus: function (status) { return true } });

API.interceptors.request.use((req) => {
    if(localStorage.getItem('profile')) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
    return req;
});

// PUBLIC
export const addVisitor = (data) => API.post('/visitors', data);
export const requestConsultation = (data) => API.post('/consultations', data);

export const fetchLangs = () => API.get('/langs');

// PAGES
export const fetchPageByName = (name) => API.get(`/pages/${name}`);
export const fetchPages = () => API.get(`/pages`);
export const fetchTexts = (names) => API.get(`/pages/text?names=${names}`);

export const fetchPartners = () => API.get('/partners');
