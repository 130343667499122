import React from 'react';
import { useSelector } from 'react-redux';

import './styles.scss';

const dictionary = {
    back: {ru: '', en: 'Back to home', kz: ''},
    oops: {ru: '', en: 'Oops.... ', kz: ''},
    pageNotFound: {ru: '', en: 'Page  not found ', kz: ''},
    pageDoesNotExist: {ru: '', en: 'This Page doesn`t exist or was removed!\nWe suggest you  back to home.', kz: ''}
}

const Page404 = () => {
    const {lang} = useSelector((state)=>state.global);
    return (
        <div id="page404" className='block'>
            <div className='container'>
                <div className='content'>
                    <div className='notfound'>
                        <div className='text-content'>
                            <div className='title'>
                                <div className='bold-40-48 color-black'>{dictionary.oops[lang]}</div>
                                <div className='semibold-24-32 color-black'>{dictionary.pageNotFound[lang]}</div>
                            </div>
                            <div className='regular-16-20 color-darkgrey'>{dictionary.pageDoesNotExist[lang]}</div>
                        </div>
                        <a href='/' className='button-48'>
                            <i></i> {dictionary.back[lang]}
                        </a>
                    </div>
                    <div id="image404"></div>
                </div>
            </div>
        </div>
    );
}

export default Page404;