import * as api from '../api';
import { FETCH_LANGS } from '../constants/actionTypes';

export const getLangs = () => async(dispatch)=>{
    try {
        const langs = await api.fetchLangs();
        dispatch({type: FETCH_LANGS, payload: langs});
    } catch (error) {
        console.log(error);
    }
}