import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import './selectInput.scss';

function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        // event.preventDefault();
        if(ref.current.contains(event.target))
            ref.current.classList.add('active');
        else
            ref.current.classList.remove('active')
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

const SelectInput = ({options=[], value={ru:'', kz: '', en: ''}, handleSelect, name, placeholder}) => {
    const {lang} = useSelector((state)=>state.global);

    const none = {name: 'none', ru: 'Нет', kz: '', en: 'None'};
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const handleChoose = (e) =>{
        wrapperRef.current.classList.remove('active')
        const index = e.target.dataset.value;
        if(index === -1){
            handleSelect(name, null);
        }else{
            const val = options[index];
            handleSelect(name, val);
        }
    }
    

    return (
        <div className="custom-select" ref={wrapperRef}>
            <input readOnly autoComplete="off"  name={name} placeholder={placeholder}  value={value ? value[lang] : ''} />
            <div className="options">
                {options && options.length>0 && options.map((option, key)=>(
                    <div className={`option ${value && value.name && value.name === option.name ? 'active' : ''}`} key={key} data-value={key} onClick={handleChoose}>{option && option[lang]}</div>
                    ))}
                <div className={`option ${(!value) ? 'active' : ''}`} data-value={-1} onClick={handleChoose}>{none[lang]}</div>
            </div>
            <span className="select-arrow-down" onClick={(e)=>{e.preventDefault(); e.target.classList.toggle('active')}}></span>
        </div>
    )
}

export default SelectInput;